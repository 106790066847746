@media print {
	.dont_print { display: none }
}

body {
	font-family: Verdana, sans-serif;
}

.container-fluid {
    padding: 32px
}

a {
    color: #0089e0;
}

.link_decoration {
    color: #0089e0;
    text-decoration: underline;
}

.logo {
	font-size: 5em;
    filter: drop-shadow(4px 2px 2px #666);
    margin-left: 4px
}

.blue-gradient {
    background: #59bdff; /* Old browsers */
    background: -moz-linear-gradient(top,  #59bdff 0%, #0c9dfc 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#59bdff), color-stop(100%,#0c9dfc)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #59bdff 0%,#0c9dfc 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #59bdff 0%,#0c9dfc 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #59bdff 0%,#0c9dfc 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #59bdff 0%,#0c9dfc 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#59bdff', endColorstr='#0c9dfc',GradientType=0 ); /* IE6-9 */
}

.btn-brand {
    background-color: linear-gradient(rgb(240, 120, 50) 0%, rgb(224, 87, 26) 100%);
}

.firebaseui-idp-password {
    background-color: #0c9dfc !important;
}

/* http://twbscolor.smarchal.com/ */
.navbar-default {
	border-color: #2099c3;
	border-width: 0;
	border-radius: 0;
    background: #59bdff; /* Old browsers */
    background: -moz-linear-gradient(top,  #59bdff 0%, #0089e0 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#59bdff), color-stop(100%,#0089e0)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #59bdff 0%,#0089e0 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #59bdff 0%,#0089e0 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #59bdff 0%,#0089e0 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #59bdff 0%,#0089e0 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#59bdff', endColorstr='#0089e0',GradientType=0 ); /* IE6-9 */
}
.navbar-default .navbar-brand {
	color: white;
	font-size: 20px;
	text-shadow: 1px 1px 1px #666;
}
.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
	color: #ffffff;
}
.navbar-default .navbar-text {
	color: #ffffff;
}
.navbar-default .navbar-nav > li > a {
	color: #ffffff;
	text-shadow: 1px 1px 1px #666;
}
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
	color: #ffffff;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
	color: #ffffff;
	background-color: #2099c3;
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
	color: #ffffff;
	background-color: #2099c3;
}
.navbar-default .navbar-toggle {
	border-color: #2099c3;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
	background-color: #2099c3;
}
.navbar-default .navbar-toggle .icon-bar {
	background-color: #ffffff;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
	border-color: #ffffff;
}
.navbar-default .navbar-link {
	color: #ffffff;
}
.navbar-default .navbar-link:hover {
	color: #ffffff;
}

@media (max-width: 767px) {
	.navbar-default .navbar-nav .open .dropdown-menu > li > a {
		color: #faffff;
	}
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
		color: #ffffff;
	}
	.navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
		color: #ffffff;
		background-color: #2099c3;
	}
}

.well {
    background-color: #d0effa;
    border: 1px solid #25abd2;
}

.form-label {
    font-weight: bolder;
    text-align: right;
}

.fa {
    color: #FF7D00;
}

.highlight {
    background-color: #fffad3;
}

.highlight_on_hover:hover {
    background-color: #fffad3;
}

.nav-item:hover {
    background-color: #fffad3;
}

.list-group-item:hover, .table-hover>tbody>tr:hover {
    background-color: #fffad3;
}

.pointer {
	cursor: pointer;
}

.alert-success {
	margin-bottom: 0;
}

/* footer links */
.footer-links {
    padding: 10px;
}
.footer-links a {
    color: white;
    margin-right: 10px;
}

@media print {
  a[href]:after {
    content: "";
  }
}
